<template>
  <div class="container">


    <div class="content">

      <div class="flex justify-center" style="position: relative;">
        <div class="back" @click="back"></div>
        <div class="title-text">あなた専用のワクチン相談チャットボット</div>
      </div>
      <div class="chat list-scroll" id="chat-record">

        <template v-for="row in chatHistory">
          <div class="message-item-left" v-if="['reply', 'tips', 'url'].includes(row.content_type)">

            <div style="position: relative;;">
              <div style="display: flex;align-items: center;">

                <div class="avatar">
                  <img style="width: 100%;" :src="require(`@/assets/mobile/${botType == 'A' ? 'bot1' : 'bot2'}.png`)"
                    alt="" srcset="" />

                </div>
                <div class="name">ハル</div>
              </div>
              <div class="message">

                <div v-html="row.content" v-if="row.content_type == 'url'"></div>

                <template v-else>{{ row.content }} </template>
                <!-- <div class="message-icon"></div> -->
              </div>
            </div>



            <div class="recommend" v-if="row.recommend" style="padding-left: 4.4rem;flex-wrap: wrap;">
              <div v-for="a, index in row.recommend" :key="a" @click="sendRecommend(index)">{{ a }}</div>

            </div>
          </div>


          <div class="message-item-right" v-if="row.content_type == 'question'">

            <div style="position: relative;justify-content: end;">

              <div style="text-align: right;" class="name">YOU</div>
              <div class="message-right">
                {{ row.content }}
                <!-- <div class="message-icon2"></div> -->
              </div>
              <!-- <img class="avatar" alt="" srcset=""> -->

            </div>

          </div>
        </template>

      </div>
      <div style="padding: 1.5rem;height: 8rem;">
        <div class="enter">

          <input type="text" style="width: calc(100% - 7rem);border:none" v-model="currentInput" :disabled="spinning"
            placeholder="HPV ワクチンに関する質問を入力してください" @keyup.enter="sendToserve" />

          <img style="    width: 6rem;
    background: rgba(30, 27, 193, 1);
    padding: 0.5rem 1.5rem;
    border-radius: 3rem;" :src="require('@/assets/mobile/icon1.png')" alt="" srcset=""
            @click="sendToserve">
        </div>
      </div>
    </div>


    <tip-modal ref="tip" />
  </div>
</template>
<script>
var recommendList = [
  'HPVワクチンとは何ですか？',
  'HPVワクチンにはどのような種類がありますか？',
  'HPVワクチンの予約方法は？'
]

var recommend_arr = [
  "HPVワクチンとは何ですか？",
  "HPVワクチンにはどのような種類がありますか？",
  "HPVワクチンの予約方法は？",
  "HPVワクチンは安全ですか？",
  "なぜHPVワクチンを接種する必要がありますか？",
  "4価ワクチンを接種した後に9価ワクチンを接種できますか？",
  "HPVワクチンの接種年齢範囲はどのくらいですか？",
  "HPVワクチンを接種した際に副作用はありますか？"
]

import { putAction, download, getAction } from '@/request/http.js'
import { Socket } from '../socket';
import tipModal from './tips.vue'


export default {
  data() {
    return {
      chatHistory: [],
      socketInstance: null,
      spinning: false,
      currentInput: '',
      recommend: [],
      nickname: "",
      bot: '',
      botType: "",
      userType: ""

    }
  },
  mounted() {

    this.init()
    this.nickname = localStorage.getItem('nickname')
    this.userType = localStorage.getItem('userType')
    this.getBot()

    // this.chatHistory = [
    //   {
    //     content_type: "reply",
    //     content: "小明，您好！我是HPV疫苗客服，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？",
    //     time: new Date().getTime(),
    //     recommend: this.recommend
    //   }
    // ]


  },
  methods: {

    init() {

      this.nickname = localStorage.getItem('nickname')
      this.chatHistory = []
      this.spinning = true;
      let id = localStorage.getItem('topicId')
      getAction('/record/history', { id: id, bot_type: localStorage.getItem('botType') }).then(res => {
        console.log('res', res)
        this.spinning = false;
        if (res.data.length == 0) {
          this.recommend = JSON.parse(JSON.stringify(recommendList))
          this.dealData({
            content_type: "reply",
            content: `こんにちは！私はあなた専用のワクチン相談チャットボット${localStorage.getItem('botType') == 'A' ? 'ハル' : "シャオイーお姉さん"}です。HPVワクチンに関する情報を提供できることを嬉しく思います。何かお手伝いできることはありますか？`
          })
        } else {
          let payload = res.data.map(ele => {
            ele.time = new Date(ele?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
            return ele
          })

          this.recommend = this.getRandomArrayElements(recommend_arr, 3)

          this.dealData(
            {
              content_type: "reply",
              content: `こんにちは！私はあなた専用のワクチン相談チャットボット${localStorage.getItem('botType') == 'A' ? 'ハル' : "シャオイーお姉さん"}です。HPVワクチンに関する情報を提供できることを嬉しく思います。何かお手伝いできることはありますか？`,
              time: new Date().getTime(),

            })

          payload.forEach(ele => {
            this.dealData(ele)
          });
        }


        console.log(this.chatHistory)

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      })

    },

    dealData(data) {
      if (data?.create_time) {
        data['time'] = new Date(data?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
      } else {
        data['time'] = new Date().toLocaleTimeString().split(':').splice(0, 2).join(':')
      }


      this.chatHistory = this.chatHistory.map(ele => {
        delete ele['recommend']
        return ele
      })

      if (['reply', 'url'].includes(data.content_type)) {


        this.chatHistory.push({
          ...data,
          recommend: this.recommend
        })

        // this.chatHistory = _arr
      } else {
        this.chatHistory.push(data)
      }
    },

    sendRecommend(index) {
      if (this.spinning) {
        return false
      }
      this.currentInput = this.recommend[index]
      this.recommend = []
      this.dealData({
        type: "question",
        content: this.currentInput
      })
      this.sendToserve()
      this.spinning = true
      // this.recommend.splice(index, 1)

    },

    async sendToserve() {

      if (!this.currentInput) {

        this.$message.warning('内容を入力してください')

        return false
      }
      this.spinning = true;

      if (!this.socketInstance) {
        await this.connectWebSocket()
      }
      this.socketInstance.send({
        "content": this.currentInput,
        "id": localStorage.getItem('topicId'),
        "type": "msg",
        "botType": localStorage.getItem('botType'),
        "language": localStorage.getItem('language')
      })

      this.status = 'waitReply'
      this.recommend = []
      this.dealData({
        content: this.currentInput,
        content_type: "question"
      })

      this.chatHistory.push({
        content: "入力中...",
        content_type: "tips"
      })


      this.$nextTick(() => {
        var container = this.$el.querySelector('#chat-record');
        container.scrollTop = container.scrollHeight;
      })

      this.currentInput = ''


    },

    getBot() {
      this.botType = localStorage.getItem('botType')
      this.bot = this.botType == 'A' ? 'ワクチンの専門家' : '看護師'
    },
    changeBot(e) {

      if (localStorage.getItem('botType') == e || this.spinning) {
        return false;
      } else {
        // this.recommend = JSON.parse(JSON.stringify(recommendList))
        this.recommend = []
        this.botType = e
        this.bot = e == 'A' ? 'ワクチンの専門家' : '看護師'
        localStorage.setItem('botType', e)
        this.chatHistory = []
        this.init()

        let chatBotList = (localStorage.getItem('botList') || '').split(',')

        if (!chatBotList.includes(e)) {
          chatBotList.push(e)

          localStorage.setItem('botList', chatBotList.join(','))
        }
      }

    },

    back() {

      if (this.spinning) {
        return false
      }
      let number = 0;
      this.chatHistory.forEach(ele => {
        if (ele.content_type == 'question') {
          number += 1
        }
      })

      if (number > 20) {

        this.$router.replace('/')

      } else {

        this.$refs.tip.visible = true
        this.$nextTick(() => {
          this.$refs.tip.count = 10
        })

      }
    },

    toPage(e) {
      window.open(e)
    },
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },


    connectWebSocket() {

      return new Promise(resolve => {

        if (!this.socketInstance) {
          this.socketInstance = new Socket(`${process.env.VUE_APP_SOCKET_BASE_URL}socket/connect`)
          this.socketInstance.connect({
            id: localStorage.getItem('topicId'),
            type: "msg",
            type: "connect"
          }, this.acceptMsg, this.errTip, this.closeTip, () => {

            setTimeout(() => {
              resolve('')
            }, 100);

          })
        }


      })


    },

    errTip(err) {
      console.log('err', err)
      this.socketInstance = null
    },

    closeTip(err) {
      console.log('closeTip', err)
      this.socketInstance = null
    },


    acceptMsg(msg) {

      let data = JSON.parse(msg.data)
      console.log('data', data)
      if (data.type == 'reply') {

        this.currentInput = ''

        this.chatHistory.pop()

        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      } else if (data.type == 'recommend') {
        this.spinning = false;
        this.recommend = data.data

        let _chat = this.chatHistory.pop()
        // _chat['recommend'] = this.recommend
        this.dealData(_chat)
      } else if (data.type == 'url') {
        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )
      }
    },








  },
  created() {

  },
  components: {
    tipModal
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  /* background-image: url('../../../assets/pc/chat.png'); */
  background: rgba(45, 30, 142, 1);
  background-size: 100% 100%;
  padding: 5rem 10rem;
  justify-content: space-between;
}



.content {
  width: 100%;
  height: 100%;
  bottom: 0;
  background: rgba(45, 30, 142, 1);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px -1px 20px 15px rgba(0, 0, 0, 0.1);

}

.chat {
  width: 100%;
  height: calc(100% - 12rem);
  padding: 3rem 2rem 2rem;

}

.enter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  border-radius: 5rem;
  overflow: hidden;
  /* background: white; */
  padding: 0 2rem;
}

.enter input {

  width: calc(100% - 7rem);
  border: none;
  border-radius: 3rem;
  height: 3.6rem;
  padding-left: 1rem;
}

.avatar {
  width: 4.4rem;
  border-radius: 50%;
  overflow: hidden;
  height: 4.4rem;
  background: white;
}

.name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 1.5rem;
}

.time {
  font-size: 1.2rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #C3C3C3;
}

.message {
  padding: 0.5rem 1rem;
  background: white;
  border-radius: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  width: calc(100% - 5rem);
  position: relative;
  word-break: break-all;
  margin-left: 4.5rem;
}

.message-item-left {

  width: 100%;
  margin-bottom: 1.8rem;
  padding: 0 20% 0 0;

}

.message-item-right {

  width: 100%;
  margin-bottom: 1.8rem;
  padding: 0 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: end;

}

.recommend div {

  padding: 0.5rem 1rem;
  margin-top: 0.8rem;
  border-radius: 2rem;
  margin-right: 1.8rem;
  cursor: pointer;
  background: rgba(53, 39, 152, 1);
  color: white;

}

.recommend div:hover {

  background: #12ba104a;


}

.message-right {
  width: fit-content;
  padding: 0.5rem 1rem;
  background: rgba(30, 27, 193, 1);
  border-radius: 2rem;
  color: white;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  position: relative;
  word-break: break-all;


}

.bot-type {

  width: 16rem;
  height: 16rem;
  position: relative;
  cursor: pointer;
}

.select-icon {
  position: absolute;
  width: 5rem;
  padding: 0.5rem;
  bottom: 0;
  right: -2rem;
  border-radius: 50%;
  background: white;
}

.bot-type .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2rem;
  background: white;
}

.bot-select-shadow1 {
  transition: transform 0.5s linear;
  transform: rotate(10deg);
  background-color: #ffe0d4 !important
}

.bot-select-shadow2 {
  transition: transform 0.5s linear;
  /* transform: rotate(-10deg); */
  background: linear-gradient(#ffa17f, #ffc5ab) !important;
}

.bot-info {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}

.img-container {
  position: relative;
  width: 100%;
  height: 50%;
}

.bot-img {
  bottom: 0;
  width: 40%;
}

.bot-type-title {
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  letter-spacing: 0.1rem;

}

.bot-type-desc {
  font-size: 1rem;
  /* background: rgb(167 167 167 / 22%); */
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}




.text {
  color: #000000;
  font-size: 1.6rem;
  letter-spacing: 1px;
  margin: 3rem 0 1.5rem;
  font-weight: 500;
}

.message-icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  left: -0.5rem;
  background: #fc7b5f;
  transform: rotate(45deg);
}

.message-icon2 {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  right: -0.5rem;
  background: #fed1c6;
  transform: rotate(45deg);
}

.back {
  width: 2rem;
  height: 2rem;
  position: absolute;
  border-radius: 50%;
  background: red;
  left: 2rem;
  top: 1rem;
  cursor: pointer;
}

.title-text {
  font-family: Inter;
  font-size: 2.5rem;
  font-weight: 300;
  text-align: center;
  color: white;

}

.name {
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3.6rem;
  text-align: center;
  color: white;

}
</style>

<style>
.message a {
  color: #E0E0E0 !important;
}
</style>